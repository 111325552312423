<template>
	<div class="box">
		<h2>支付方式</h2>
		
	</div>
</template>

<script>
    import { balance, withdrawConfig, balanceDetail } from "@/api/member/account"
    export default {
        name: "account",
        components: {},
        data: () => {
            return {
                list: [],
                loading: true,
				yes: true
            }
        },
        created() {
        },
		mounted() {
			
		},
        methods: {
            
        }
    }
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
	
	h2 {
		font-size: 24px;
		color: $ns-text-color-black;
		padding-bottom: 5px;
		border-bottom: 1px solid #d9d9d9;
		
		.balance {
			margin-left: 10px;
			color: red;
			vertical-align: -2px;
		}
		
		.hints {
			margin-left: 20px;
			font-size: 12px;
			color: $ns-text-color-gray;
			font-weight: 400;
			vertical-align: -1px;
		}
	}
	
	
}
</style>
